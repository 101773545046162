$(document).ready(function () {

    window.$ = $;  // for jquery debugging

    $('#item');
    jQuery('#item');

    if (typeof showModal !== 'undefined') {
        if (showModal) {
            $('#exampleModal').modal('show')
        }
    }

    // init with element
    var grid = document.querySelector('.grid');
    var msnry = null;

    if (grid !== null) {

        msnry = new Masonry(grid, {
            // options...
            itemSelector: '.grid-item',
            columnWidth: 1
        });

    }

    $(".arrow-top").click(function () {

        if ($(document).width() < 576) {

            if ($(".fb-popup-img").css("display") == "none") {
                $(".fb-popup-img").toggle(500)
                $(".arrow-top").css({
                    "-webkit-transform": "rotate(0deg)",
                    "-moz-transform": "rotate(0deg)",
                    "transform": "rotate(0deg)"
                });
                $(".arrow-bottom").fadeIn(500)
            } else {
                $(".fb-popup-img").toggle(500)
                $(".arrow-top").css({
                    "-webkit-transform": "rotate(180deg)",
                    "-moz-transform": "rotate(180deg)",
                    "transform": "rotate(180deg)"
                });
                $(".arrow-bottom").fadeOut(500)

                $(".fb-popup-img").animate({height: 418}, 500);
                $(".arrow-bottom").css({
                    "-webkit-transform": "rotate(270deg)",
                    "-moz-transform": "rotate(270deg)",
                    "transform": "rotate(270deg)"
                });
            }

        } else {
            if ($(".fb-popup-img").css("display") == "none") {
                $(".fb-popup-img").toggle(500)
                $(".arrow-top").css({
                    "-webkit-transform": "rotate(0deg)",
                    "-moz-transform": "rotate(0deg)",
                    "transform": "rotate(0deg)"
                });
                $(".fb-popup").animate({top: 0}, 500);
                $(".fb-popup-img").animate({height: 438}, 500);
                $(".arrow-bottom").fadeIn(500)
            } else {
                $(".fb-popup-img").toggle(500)
                $(".fb-popup-img").animate({height: 438}, 500);
                $(".arrow-top").css({
                    "-webkit-transform": "rotate(180deg)",
                    "-moz-transform": "rotate(180deg)",
                    "transform": "rotate(180deg)"
                });
                $(".arrow-bottom").fadeOut(500)

                $(".fb-popup").animate({top: 308}, 500);
                $(".arrow-bottom").css({
                    "-webkit-transform": "rotate(90deg)",
                    "-moz-transform": "rotate(90deg)",
                    "transform": "rotate(90deg)"
                });
            }
        }

    })

    $(".arrow-bottom").click(function () {
        var click = $(this).data('clicks');

        if ($(document).width() < 576) {

            if ($(".fb-popup-img").css("height") == "130px") {
                $(".fb-popup-img").animate({height: 418}, 500);
                $(".arrow-bottom").css({
                    "-webkit-transform": "rotate(270deg)",
                    "-moz-transform": "rotate(270deg)",
                    "transform": "rotate(270deg)"
                });

            } else {
                $(".fb-popup-img").animate({height: 130}, 500);
                $(".arrow-bottom").css({
                    "-webkit-transform": "rotate(90deg)",
                    "-moz-transform": "rotate(90deg)",
                    "transform": "rotate(90deg)"
                });

            }

        } else {
            if ($(".fb-popup-img").css("height") == "130px") {
                $(".fb-popup-img").animate({height: 438}, 500);
                $(".fb-popup").animate({top: 0}, 500);
                $(".arrow-bottom").css({
                    "-webkit-transform": "rotate(90deg)",
                    "-moz-transform": "rotate(90deg)",
                    "transform": "rotate(90deg)"
                });

            } else {
                $(".fb-popup-img").animate({height: 130}, 500);
                $(".fb-popup").animate({top: 308}, 500);
                $(".arrow-bottom").css({
                    "-webkit-transform": "rotate(270deg)",
                    "-moz-transform": "rotate(270deg)",
                    "transform": "rotate(270deg)"
                });

            }
            ;
        }

    })

    var hamburger = $('#hamburger-icon');

    hamburger.click(function () {

        if ($( "#navbarNav" ).is( ".show" )) {

            hamburger.removeClass('active');
        }
        else {
            hamburger.addClass('active');
        }
    });

    $(".txt-1").fadeIn(500)
    setTimeout(function () {
        $(".txt-2").fadeIn(500);
    }, 1000);
    setTimeout(function () {
        $(".txt-3").fadeIn(500);
    }, 2000);

    setTimeout(function () {
        $(".dali-head").animate({
            right: 0
        }, 1500)
    }, 2000)

    $('.appear').each(function (i) {
            $(this).delay(i * 300).animate({
                opacity: 1

            }, 500, function () {
            });
        }
    );

});





