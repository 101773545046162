// Custom css

require('../styles.scss');

// Fonts

import font from '../fonts/74307502.ttf';
import montserratLight from '../fonts/Montserrat-Light.otf';
import montserratRegular from '../fonts/Montserrat-Regular.otf';
import myriadPro from '../fonts/MyriadPro-Regular.otf';

// Images

import favicon32 from '../favicon32x32.ico';
import favicon57 from '../favicon57x57.ico';
import favicon76 from '../favicon76x76.ico';
import favicon96 from '../favicon96x96.ico';
import favicon120 from '../favicon120x120.ico';
import favicon128 from '../favicon128x128.ico';
import favicon144 from '../favicon144x144.ico';
import favicon152 from '../favicon152x152.ico';
import favicon180 from '../favicon180x180.ico';
import favicon192 from '../favicon192x192.ico';
import favicon196 from '../favicon196x196.ico';
import favicon228 from '../favicon228x228.ico';

import artists from '../img/artists/artists.jpg';
import artists1 from '../img/artists/artists1.jpg';
import artists2 from '../img/artists/artists2.jpg';
import artists3 from '../img/artists/artists3.jpg';
import artists4 from '../img/artists/artists4.jpg';
import artists5 from '../img/artists/artists5.jpg';
import artists6 from '../img/artists/artists6.jpg';
import artists7 from '../img/artists/artists7.jpg';
import artists8 from '../img/artists/artists8.jpg';
import artists9 from '../img/artists/artists9.mp4';
import artists10 from '../img/artists/artists10.mp4';
import artists11 from '../img/artists/artists11.mp4';
import artists12 from '../img/artists/artists12.mp4';
import artists13 from '../img/artists/artists13.mp4';
import artists14 from '../img/artists/artists14.mp4';
import artists15 from '../img/artists/artists15.mp4';
import artists16 from '../img/artists/artists16.mp4';
import artists17 from '../img/artists/artists17.mp4';
import artists18 from '../img/artists/artists18.mp4';
import artists19 from '../img/artists/artists19.mp4';

import beverage from '../img/beverage/beverage.jpg';
import beverage1 from '../img/beverage/beverage1.jpg';
import beverage2 from '../img/beverage/beverage2.jpg';
import beverage3 from '../img/beverage/beverage3.jpg';
import beverage4 from '../img/beverage/beverage4.jpg';
import beverage5 from '../img/beverage/beverage5.jpg';
import beverage6 from '../img/beverage/beverage6.jpg';
import beverage7 from '../img/beverage/beverage7.jpg';
import beverage8 from '../img/beverage/beverage8.jpg';

import food from '../img/food/food.jpg';
import food1 from '../img/food/food1.jpg';
import food2 from '../img/food/food2.jpg';
import food3 from '../img/food/food3.jpg';
import food4 from '../img/food/food4.jpg';
import food5 from '../img/food/food5.jpg';
import food6 from '../img/food/food6.jpg';
import food7 from '../img/food/food7.jpg';
import food8 from '../img/food/food8.jpg';
import food9 from '../img/food/food9.jpg';
import food0 from '../img/food/food10.jpg';
import food11 from '../img/food/food11.jpg';

import location from '../img/location/location.jpg';
import location1 from '../img/location/location1.jpg';
import location2 from '../img/location/location2.jpg';
import location3 from '../img/location/location3.jpg';
import location4 from '../img/location/location4.jpg';
import location5 from '../img/location/location5.jpg';

import venue from '../img/venue/venue.jpg';
import venue1 from '../img/venue/venue1.jpg';
import venue2 from '../img/venue/venue2.jpg';
import venue3 from '../img/venue/venue3.jpg';
import venue4 from '../img/venue/venue4.jpg';
import venue5 from '../img/venue/venue5.jpg';
import venue6 from '../img/venue/venue6.jpg';
import venue7 from '../img/venue/venue7.mp4';

import aboutBackground from '../img/about-bcground.jpg';
import bacground from '../img/background.jpg';
import dali from '../img/dali.png';
import facebook from '../img/facebook.svg';
import fb from '../img/fb.jpg';
import fb1 from '../img/fb.svg';
import fbPopup from '../img/fb-popup.jpg';
import homeBackground from '../img/home-bcground.jpg';
import insta from '../img/insta.svg';
import logoBlack from '../img/logo-black.svg';
import logoWhite from '../img/logo-white.svg';
import menuBtn from '../img/menu-btn.png'
import musicImg1 from '../img/music-img1.jpg';
import musicImg2 from '../img/music-img2.jpg';
import placeholder from '../img/placeholder.svg';
import rightArrow from '../img/right-arrow.svg';
import rsvp from '../img/rsvp.svg';
import tripadvisor from '../img/tripadvisor.svg';

import homeVideo from '../img/home-video.mp4';

// Custom script

var $ = require("jquery");

import 'bootstrap';
import Masonry from 'masonry-layout';
window.Masonry = Masonry;
import scripts from '../js/scripts';
